import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "../pages/SignIn";

import AllUsers from "../pages/AllUsers";
import PrivateRoute from "./PrivateRoute";

const RoutesPage = () => {
  return (
    <>
      <BrowserRouter>
        {/* USER PANEL ROUTES */}
        <Routes>
          <Route element={<PrivateRoute />}>
          <Route path="/all-users" element={<AllUsers />} />
          </Route>
          <Route exact path="/" element={<SignIn />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default RoutesPage;
