export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_REQUEST = "USER_REGISTER_SUCCESS";
export const USER_LOGIN_SUCCESS = "USER_REGISTER_FAIL";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";

export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";

export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const INFO_CATEGORY_SUCCESS = "INFO_CATEGORY_SUCCESS";
export const GET_TRADE_PEOPLE = "GET_TRADE_PEOPLE";
export const VIEW_TRADE_PEOPLE_DETAIL = "VIEW_TRADE_PEOPLE_DETAIL";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const VIEW_USER_DETAIL = "VIEW_USER_DETAIL";
export const GET_ALL_PENDING_VERFICATIONS = "GET_ALL_PENDING_VERFICATIONS";
export const ALL_CATEGORY = "ALL_CATEGORY";
export const GET_ALL_ENQUIRY = "GET_ALL_ENQUIRY";

export const GET_ALL_SERVICE = "GET_ALL_SERVICE";
export const ALL_SUB_CATEGORY = "ALL_SUB_CATEGORY";
export const ADD_SUB_CATEGORY_SUCCESS = "ADD_SUB_CATEGORY_SUCCESS";
export const ADD_CATEGORY_SERVICE_SUCCESS = "ADD_CATEGORY_SERVICE_SUCCESS";
export const UPDATE_CATEGORY_SERVICE_SUCCESS =
  "UPDATE_CATEGORY_SERVICE_SUCCESS";
export const SERVICE_CATEGORY_BY_ID_SUCCESS = "SERVICE_CATEGORY_BY_ID_SUCCESS";
export const DELETE_SERVICE_CATEGORY_SUCCESS =
  "DELETE_SERVICE_CATEGORY_SUCCESS";
export const GET_TRADER_ENQUIRIES = "GET_TRADER_ENQUIRIES";
export const GET_ENQUIRY_DETAILS = "GET_ENQUIRY_DETAILS";
export const GET_ENQUIRY_CHAT = "GET_ENQUIRY_CHAT";
export const GET_ALL_REVIEWS="GET_ALL_REVIEWS";
export const VIEW_REVIEW_DETAIL="VIEW_REVIEW_DETAIL";
export const UPDATE_STATUS='UPDATE_STATUS'
